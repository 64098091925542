body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  min-height: calc(100vh - 45px);
  /* will cover the 100% of viewport */
  /* overflow: hidden; */
  /* display: block; */
  position: relative;
  padding-bottom: 20px;
  /* height of your footer */
}

.move-header-to-top {
  position: absolute;
  top: -40px;
  left: 50px;
  right: 50px;
}

/* .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
} */

.footer {
  position: absolute;
  bottom: 1em;
  width: 100%;
  text-align: center;
}

.header {
  margin: 2em;
  padding-bottom: 1em;
  border-bottom: 1px solid;
}

.header a {
  margin: 0 1em 0 0;
  font-size: 1.2em;
  text-decoration: none;
}

.content {
  margin-left: 2em;
  font-size: 1.5em;
}

.message {
  color: red;
}

a:hover,
a:focus {
  text-decoration: none !important;
}

.highlight {
  background-color: yellow;
}

.breadcrumb {
  padding: 0;
  background-color: white;
  font-size: 75%;
}

.bold {
  font-weight: bold;
}

.fw-normal {
  font-weight: normal;
}

.c-pointer {
  cursor: pointer;
}

.spinner {
  animation: spin infinite 3s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

svg {
  margin-top: -2px;
}

/* Font size */
.f-sm {
  font-size: 0.85rem;
}

.f-xs {
  font-size: 0.7rem;
}

.f-normal {
  font-size: 1rem;
}

.f-lg {
  font-size: 1.15rem;
}

.f-xl {
  font-size: 1.3rem;
}

.f-xx {
  font-size: 1.5rem;
}

/* Color */
.blue {
  color: blue;
}

.green {
  color: green;
}

.red {
  color: red;
}

.cyan {
  color: cyan;
}

.grey {
  color: grey;
}

.lightgrey {
  color: lightgrey;
}

.black {
  color: black;
}

.white {
  color: white;
}

.blueviolet {
  color: blueviolet;
}

.lightgreen {
  color: lightgreen;
}

.salmon {
  color: salmon;
}

.link {
  color: #007bff;
}

@media (max-width: 768px) {
  .d-lg-only {
    display: none;
  }
}

@media (min-width: 768px) {
  .d-sm-only {
    display: none;
  }
}

.b-light {
  border: 1px solid lightgrey;
  border-radius: 3px;
}

.b-dark {
  border: 1px solid darkgrey;
  border-radius: 5px;
}

.b-dark-2 {
  border: 2px solid darkgrey;
  border-radius: 5px;
}

.bg-light {
  background-color: lightgray;
}

.bg-dark {
  background-color: darkgray;
}

.bg-lightgreen {
  background-color: lightgreen;
}

h6,
.h6 {
  color: blueviolet;
}

.badge {
  /* background-color: #eee; */
  /* border: 1px solid #ccc; */
  font-weight: normal !important;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.8;
}

/* span[disabled] {
  pointer-events: none;
  opacity: 0.95;
} */
.wrap {
  white-space: normal;
}

.nowrap {
  white-space: nowrap;
}

.clear-both {
  clear: both;
}

tr:hover td {
  background-color: azure;
}

table tr.separator {
  border-bottom: 1px solid black;  
}

table tr.separator-blueviolet {
  border-bottom: 1px solid blueviolet;  
}

.one-line {
  /* width: 100%; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.two-lines {
  /* width: 100%; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.three-lines {
  /* width: 200px; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.four-lines {
  width: 200px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.five-lines {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.fullscreen {
  background: #fff !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mt10 {
  margin-top: 10px;
}

/* Get rid of the up/down spinner for number input */
/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */
/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */

.compact ul {
  padding-left: 1.5em;
}

.nav-tabs .nav-link.active {
  font-weight: 700;
}

.btn-link {
  text-decoration: none;
}

a {
  color: var(--bs-link-color);
  text-decoration: none;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
  text-decoration: none;
}

.drag-zone {
  position: absolute;
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  user-select: none;
  -webkit-app-region: drag;
  top: 0px;
  right: 0px;
  wid: 160px;
  height: 32px;
  background-color: #ddd;
}

.drag-zone:before {
  content: '.';
  position: absolute;
  left: 5px;
  font-size: 20px;
  line-height: 10px;
  color: #aaa;
  text-shadow: 0 5px #aaa, 0 10px #aaa, 5px 0 #aaa, 5px 5px #aaa, 5px 10px #aaa, 10px 0 #aaa, 10px 5px #aaa, 10px 10px #aaa;
}

th {
  color: #aaa;
}

/* 
  Bootstrap CSS sets the max-width of a popover to 276px in the popover class.  
  Passing style argument to modify max-width doesn't work because the reactstrap Popover component creates two div elements, and the style arguments get passed to the inner one.
*/
.popover {
  max-width: 400px;
}